import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Спортзал Актив Лайф - Ваш шлях до здорового життя
			</title>
			<meta name={"description"} content={"Спортзал Актив Лайф пропонує сучасне обладнання, професійних тренерів та широкий спектр фітнес-програм. Приєднуйтесь до нас для досягнення ваших спортивних цілей."} />
			<meta property={"og:title"} content={"Головна | Спортзал Актив Лайф - Ваш шлях до здорового життя"} />
			<meta property={"og:description"} content={"Спортзал Актив Лайф пропонує сучасне обладнання, професійних тренерів та широкий спектр фітнес-програм. Приєднуйтесь до нас для досягнення ваших спортивних цілей."} />
			<meta property={"og:image"} content={"https://kamaflorex.org/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kamaflorex.org/img/1729082.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kamaflorex.org/img/1729082.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kamaflorex.org/img/1729082.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kamaflorex.org/img/1729082.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kamaflorex.org/img/1729082.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kamaflorex.org/img/1729082.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Спортзал Актив Лайф - Ваш шлях до здорового життя
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					font="normal 400 22px/1.1 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 80px 0px 80px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Ласкаво просимо до спортзалу "Актив Лайф"! Наш заклад створений для тих, хто цінує здоровий спосіб життя і прагне досягти нових спортивних висот. У нас ви знайдете все необхідне для ефективних тренувань і досягнення ваших цілей.
				</Text>
				<Button
					margin="0px 0 50px 0px"
					padding="14px 35px 14px 35px"
					background="--color-primary"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-dark"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					border-radius="5px"
					color="#111"
				>
					Контакти
				</Button>
			</Box>
			<Image
				src="https://kamaflorex.org/img/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://kamaflorex.org/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Сучасне обладнання
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ми пропонуємо найсучасніше обладнання для силових та кардіотренувань. Наші тренажери від провідних світових виробників забезпечують ефективність та безпеку кожного тренування.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						Різноманітність фітнес-програм
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Йога
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Йога допомагає зміцнити м'язи, покращити гнучкість та збалансувати дух. Наші заняття йоги підходять як для початківців, так і для досвідчених практикуючих. Вони включають різні стилі, такі як Хатха-йога, Віньяса, і Аштанга.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Пілатес
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Пілатес – це система вправ, що спрямована на покращення постави, зміцнення м'язів кора і покращення загального фізичного стану. Наші тренери використовують спеціальне обладнання та матс для забезпечення максимального ефекту від занять.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Аеробіка
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Аеробіка – чудовий спосіб спалити калорії, зміцнити серцево-судинну систему і підняти настрій. У нас є різні види аеробіки, включаючи степ-аеробіку, танцювальну аеробіку і аквааеробіку.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Тренування на витривалість
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Ці тренування спрямовані на покращення загальної фізичної витривалості, сили та швидкості. Вони включають інтенсивні інтервальні тренування (HIIT), кросфіт, та функціональні тренування, які допомагають досягти оптимальної форми і збільшити витривалість.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						"Актив Лайф" забезпечує комфортні умови для тренувань
						</Text>
					</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Сучасне обладнання
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Ми пропонуємо найсучасніше обладнання для силових та кардіотренувань. Наші тренажери від провідних світових виробників забезпечують ефективність та безпеку кожного тренування.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Професійні тренери
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Наші тренери - це кваліфіковані фахівці з великим досвідом роботи. Вони допоможуть вам скласти індивідуальну програму тренувань, підберуть оптимальний режим харчування та нададуть корисні поради щодо досягнення ваших цілей.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://kamaflorex.org/img/3.jpg) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline2"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Приєднуйтесь до нас
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Запишіться на безкоштовне пробне тренування та оцініть всі переваги нашого спортзалу.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});